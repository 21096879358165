import React, { useState } from "react";
import ByBarcode from './ByBarcode'
import ByModel from './ByModel'
import { Box, Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


function App() {
    // Set up state for hold response data
    const [view, setView] = useState('ByBarcode');

    const handleViewChange = (event, nextView) => {
        if (nextView !== null) {
            setView(nextView);
        }
    };

    return (
        <React.Fragment>
            <Container maxWidth="md" sx={{ m: '5em auto 0 auto' }}>
                <Typography variant="h5" gutterBottom sx={{ m: '0 0 1em 0' }}>
                    <Box component="img" src={`${process.env.PUBLIC_URL}/logo.png`} alt="ExtraStar" sx={{ width: "100px", marginRight: "10px" }} />
                </Typography>
                <Box sx={{ p: "1em 0 1em 0", textAlign: "center" }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={view}
                        exclusive
                        onChange={handleViewChange}
                        aria-label="view switch"
                    >
                        <ToggleButton value="ByBarcode">按条形码输入</ToggleButton>
                        <ToggleButton value="ByModel">按型号输入</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {view === 'ByBarcode' ? <ByBarcode /> : <ByModel />}
                <Box sx={{ p: "1em 0 0 0" }}>
                    <Alert severity="warning">填写产品型号时请忽略 “/”, 例如: 当遇到 “T8/58W” 请填写 “T858W”</Alert>
                    <Alert severity="warning">PDF 打印时请使用原尺寸打印, 不要选择缩放</Alert>
                    <Alert severity="warning">Excel 文件模板最多支持 100 行条形码</Alert>
                    <Alert severity="warning">意大利客户请注意: 如果您是黑白打印效果制作能效帖, 请一定要选择 “打印黑白版” !</Alert>
                </Box>
                <Box sx={{ p: "1em 0 0 0" }}>
                    <div>如有任何问题, 请及时反馈到企业微信账号, 谢谢!</div>
                    <img src={`${process.env.PUBLIC_URL}/qrcode.jpg`} alt="ExtraStar 企业微信" width="200px" />
                </Box>
                <Box sx={{ p: "1em 0 0 0" }}>
                    <img src={`${process.env.PUBLIC_URL}/brief.jpg`} alt="ExtraStar" width="550px"></img>
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default App
