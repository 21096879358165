import React, { useState } from "react";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function App() {
  // Set up state for hold response data
  const [inputs, setInputs] = useState([{ model: '', qty: '', color_ok: '', bw_ok: '' }]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAddClick = () => {
    setInputs([...inputs, { model: '', qty: '', color_ok: '', bw_ok: '' }]);
  };

  const handleBlur = async (index, event) => {
    let model = event.target.value;
    const newInputs = [...inputs];
    //const apiUrl = `check_file_exists?model=${model}`
    const apiUrl = `api/check_file_exists?model=${model}`
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      newInputs[index]['color_ok'] = result['color_ok']
      newInputs[index]['bw_ok'] = result['bw_ok']
      setInputs(newInputs)
    } catch (error) {
      setError(error.message);
    }
  };

  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index][event.target.name] = event.target.value.trim().toUpperCase();
    setInputs(newInputs);
    setData(null);
  };

  const handleClearInputs = () => {
    const newInputs = [{ model: '', qty: '' }]
    setInputs(newInputs);
    setData(null);
  };

  const reprint = () => {
    setData(null);
  };

  const clearUnprintableItems = () => {
    const newInputs = inputs.filter(input => {
      return input.color_ok === "yes" && input.bw_ok === "yes";
    });
    setInputs(newInputs);
  };

  const handleDelete = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
    setData(null);
  };

  const handleSubmit = async (mode) => {
    setLoading(true);
    setError(null);

    const queryParams = inputs.map(input => `model=${encodeURIComponent(input.model)}&qty=${encodeURIComponent(input.qty)}`).join('&');
    //const apiUrl = `generate_tags?mode=${mode}&${queryParams}`;
    const apiUrl = `api/generate_tags?mode=${mode}&${queryParams}`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }

  };

  return (
    <React.Fragment>
      <Box>
        <Stack spacing={1}>
          {inputs.map((input, index) => (
            <Item key={index}>
              <TextField
                variant="standard"
                name="model"
                error={(input.color_ok !== "yes" || input.bw_ok !== "yes") ? true : false}
                value={input.model}
                onBlur={(event) => handleBlur(index, event)}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="产品型号"
                helperText={(() => {
                  let color = input.color_ok !== "yes" ? "彩色版本不存在" : ""
                  let bw = input.bw_ok !== "yes" ? "黑白版本不存在" : ""
                  if (input.color_ok !== '' && input.bw_ok !== '') {
                    if (input.color_ok !== "yes" || input.bw_ok !== "yes") return color + " " + bw;
                  }
                })()}
              />
              <TextField
                variant="standard"
                type="number"
                name="qty"
                value={input.qty}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="数量"
              />
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Item>
          ))}
        </Stack>
        {inputs.some(input => {
          return input.color_ok !== "yes" || input.bw_ok !== "yes";
        }) && <Button
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => clearUnprintableItems()}>
            一键清除无法打印的型号 (可以手动重新添加)
          </Button>}
        <Box sx={{ textAlign: "center", marginTop: "1em" }}>
          {!data && (<ButtonGroup variant="contained">
            <Button color="primary" startIcon={<AddCircleOutlineSharpIcon />} onClick={handleAddClick}>添加新型号</Button>
            <Button
              color="primary"
              disabled={inputs.some(input => input.color_ok !== "yes")}
              startIcon={<LocalPrintshopOutlinedIcon />}
              onClick={() => handleSubmit('COLOR')}>
              打印彩色版
            </Button>
            <Button
              color="primary"
              disabled={inputs.some(input => input.bw_ok !== "yes")}
              startIcon={<LocalPrintshopOutlinedIcon />}
              onClick={() => handleSubmit('BW')}>
              打印黑白版
            </Button>
          </ButtonGroup>)}
          {data && (<ButtonGroup variant="contained">
            <Button
              startIcon={<CloudDownloadOutlinedIcon />}
              component="a"
              href={data.download}
              download={data.filename}
              color="success">
              下载能效贴
            </Button>
            <Button startIcon={<CleaningServicesOutlinedIcon />} onClick={handleClearInputs} color="primary">全部清空</Button>
            <Button onClick={reprint} color="secondary">选择打印颜色</Button>
          </ButtonGroup>)}
        </Box>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {data && data.errMsg && (<ul>
          {data.errMsg.map(msg => (
            <li>{msg}</li>
          ))}
        </ul>)}
      </Box>
    </React.Fragment >
  );
}

export default App
