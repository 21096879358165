import React, { useState } from "react";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import DownloadIcon from '@mui/icons-material/Download';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function App() {
  // Set up state for hold response data
  const [inputs, setInputs] = useState([{ barcode: '', model: '', qty: '', color_ok: '', bw_ok: '' }]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);

  const handleAddClick = () => {
    setInputs([...inputs, { barcode: '', model: '', qty: '', color_ok: '', bw_ok: '' }]);
    setError(null)
  };

  const handleBarcodeInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index][event.target.name] = event.target.value;
    newInputs[index]['model'] = '';
    setInputs(newInputs);
    setData(null);
  };

  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index][event.target.name] = event.target.value;
    setInputs(newInputs);
    setData(null);
  };

  const handleBlur = async (index, event) => {
    if (!inputs[index]['model'] && event.target.value) {
      let barcode = event.target.value;
      const newInputs = [...inputs];
      //const apiUrl = `query_barcodes?barcode=${barcode}`
      const apiUrl = `api/query_barcodes?barcode=${barcode}`
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        Object.assign(newInputs[index], result[0]);
        setInputs(newInputs);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleClearInputs = () => {
    const newInputs = [{ barcode: '', model: '', qty: '' }]
    setInputs(newInputs);
    setData(null);
  };

  const reprint = () => {
    setData(null);
  };

  const handleDelete = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
    setData(null);
  };

  const clearUnprintableItems = () => {
    const newInputs = inputs.filter(input => {
      return input.color_ok === "yes" && input.bw_ok === "yes";
    });
    setInputs(newInputs);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    const formData = new FormData();
    formData.append('file', selectedFile);

    //const apiUrl = `query_barcodes_excel`;
    const apiUrl = `api/query_barcodes_excel`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Network response was not ok!');
      } else {
        setFile(null)
      }

      const newInputs = await response.json();
      setInputs(newInputs);
      setData(null);
    } catch (error) {
      setError(error.message || '上传文件失败')
      setFile(null)
    }
  };

  const handleSubmit = async (mode) => {
    setLoading(true);
    setError(null);

    const queryParams = inputs.map(input => `model=${encodeURIComponent(input.model)}&qty=${encodeURIComponent(input.qty)}`).join('&');
    //const apiUrl = `generate_tags?mode=${mode}&${queryParams}`;
    const apiUrl = `api/generate_tags?mode=${mode}&${queryParams}`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }

  };

  return (
    <React.Fragment>
      <Box>
        <Stack spacing={1}>
          {inputs.map((input, index) => (
            <Item key={index}>
              <TextField
                variant="standard"
                name="barcode"
                error={input.model === "NA"}
                value={input.barcode}
                onChange={(event) => handleBarcodeInputChange(index, event)}
                placeholder="条形码"
                onBlur={(event) => handleBlur(index, event)}
                helperText={input.model === "NA" ? "条码不存在" : ""}
              />
              <TextField
                variant="standard"
                disabled
                error={(input.color_ok !== "yes" || input.bw_ok !== "yes") ? true : false}
                name="model"
                value={input.model}
                placeholder="产品型号"
                helperText={(() => {
                  let color = input.color_ok !== "yes" ? "彩色版本不存在" : ""
                  let bw = input.bw_ok !== "yes" ? "黑白版本不存在" : ""
                  if (input.color_ok !== '' && input.bw_ok !== '') {
                    if (input.color_ok !== "yes" || input.bw_ok !== "yes") return color + " " + bw;
                  }
                })()}
              />
              <TextField
                variant="standard"
                name="qty"
                type="number"
                value={input.qty}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="数量"
              />
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Item>
          ))}
        </Stack>
        {inputs.some(input => {
          return input.color_ok !== "yes" || input.bw_ok !== "yes";
        }) && <Button
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => clearUnprintableItems()}>
            一键清除无法打印的型号 (可以手动重新添加)
          </Button>}

        <Box sx={{ textAlign: "center", marginTop: "1em" }}>
          {!data && (<ButtonGroup variant="contained">
            <Button color="primary" onClick={handleAddClick} startIcon={<AddCircleOutlineSharpIcon />}>添加条形码</Button>
            <Button
              component="label"
              color="primary"
              disabled={!!file}
              startIcon={<CloudUploadOutlinedIcon />}
            >
              上传 Excel
              <VisuallyHiddenInput type="file" onChange={(event) => handleFileChange(event)} />
            </Button>
            <Button
              startIcon={<DownloadIcon />}
              component="a"
              href="Excel_Template.xlsx"
              download="Excel_Template.xlsx"
              color="success">
              下载 Excel 模板
            </Button>
            <Button
              color="primary"
              disabled={inputs.some(input => input.color_ok !== "yes") ? true : false}
              startIcon={<LocalPrintshopOutlinedIcon />}
              onClick={() => handleSubmit('COLOR')}>
              打印彩色版
            </Button>
            <Button
              color="primary"
              disabled={inputs.some(input => input.bw_ok !== "yes") ? true : false}
              startIcon={<LocalPrintshopOutlinedIcon />}
              onClick={() => handleSubmit('BW')}>
              打印黑白版
            </Button>
          </ButtonGroup>)}
          {data && (<ButtonGroup variant="contained">
            <Button
              startIcon={<CloudDownloadOutlinedIcon />}
              component="a"
              href={data.download}
              download={data.filename}
              color="success">
              下载能效贴
            </Button>
            <Button startIcon={<CleaningServicesOutlinedIcon />} onClick={handleClearInputs} color="primary">全部清空</Button>
            <Button onClick={reprint} color="secondary">选择打印颜色</Button>
          </ButtonGroup>)}
        </Box>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {data && data.errMsg && (<ul>
          {data.errMsg.map(msg => (
            <li>{msg}</li>
          ))}
        </ul>)}
      </Box>
    </React.Fragment>
  );
}

export default App
